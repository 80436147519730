import React, { useState } from 'react';
import { TechRadarComponent } from '@backstage/plugin-tech-radar';
import { Header, Select, SelectItem } from '@backstage/core-components';

const radars: SelectItem[] = [
    { label: 'Application Software', value: 'tech-radar-application-software' },
    { label: 'Infrastructure', value: 'tech-radar-infrastructure' },
    { label: 'Security & Compliance', value: 'tech-radar-security-compliance' },
    { label: '3rd Party Commercial Software', value: 'tech-radar-third-party' }
];

export const TechRadarPage = () => {
    const [selectedRadar, setSelectedRadar] = useState(radars[0].value);
  
    return (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100vh' }}>
        <Header title="Tech Radar" subtitle="Select a radar to view" />
        <div style={{ alignSelf: 'start', marginLeft: '20px' }}>
          <Select
            selected={selectedRadar}
            onChange={value => setSelectedRadar(value)}
            items={radars}
          />
        </div>
  
        <div style={{ flexGrow: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <TechRadarComponent
            width={1500}
            height={800}
            id={selectedRadar}
          />
        </div>
      </div>
    );
  };