import {
TechRadarApi,
TechRadarLoaderResponse,
} from '@backstage/plugin-tech-radar';
import fetch from 'node-fetch';
import { DiscoveryApi } from '@backstage/core-plugin-api';
  
  export class TechRadarClient implements TechRadarApi {
    private discoveryApi: DiscoveryApi;

    constructor(config: DiscoveryApi) {
      this.discoveryApi = config;
    }

    async load(id: string | undefined): Promise<TechRadarLoaderResponse> {
      const backendUrl = await this.discoveryApi.getBaseUrl('proxy');
      const url = `${backendUrl}/azureDevOps/avidxchange/Architecture%20Center%20of%20Excellence/_apis/sourceProviders/TfsGit/filecontents?repository=Tech-Radar&path=/${id}.json&commitOrBranch=master`
  
      let data;
      try {
        const response = await fetch(url);
        data = await response.json();
      } catch (error) {
        console.error('Error:', error);
      }
      if (typeof data === 'object' && data !== null) {
        // Now you can use the spread operator on `data`
        const newData = { ...data };
      } else {
        console.error('Data is not an object:', data);
        console.log(url);
      }
  
      // For example, this converts the timeline dates into date objects
      return {
        ...data,
        entries: data.entries.map((entry: { timeline: any[]; }) => ({
          ...entry,
          timeline: entry.timeline.map((timeline: any) => ({
            ...timeline,
            date: new Date(timeline.date),
          })),
        })),
      };
    }
  }